html,
body,
#root {
	margin: 0;
	padding: 0;
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	overflow: hidden;
}

body {
	font-family: 'Roboto';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.MuiOutlinedInput-input:-webkit-autofill {
	box-shadow: none !important;
}

textarea {
	background: transparent;
	border: 1px solid #e5e7eb;
	border-radius: 8px;
	padding: 8px;
	font-family: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
	resize: vertical;
	min-height: 40px;
}

/* map chart */
.rsm-geography {
	outline: none !important;
}

/* toaster */
.toast-neutral .Toastify__progress-bar {
	background: #6b7280;
}

.toast-neutral .Toastify__toast-icon svg {
	fill: #6b7280;
}

/* scrollbar */
::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

::-webkit-scrollbar-track {
	background: transparent;
	width: 0;
}

::-webkit-scrollbar-thumb {
	background-color: #5277a2;
	border-radius: 100px;
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}

::-webkit-scrollbar-thumb:hover {
	background-color: #4b5563;
	border-radius: 100px;
	border: 4px solid rgba(0, 0, 0, 0);
	background-clip: padding-box;
}

::-webkit-scrollbar-corner {
	background: transparent;
}

/* puppeter works incorrect with shadows */
div.pw-pdf-element * {
	box-shadow: none !important;
}

.ant-picker-presets ul {
	padding-right: 0 !important;
}

.ant-picker-presets ul li {
	padding-inline-end: 0 !important;
}

.ant-picker-dropdown {
	z-index: 2222;
}
